import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export default function () {
    window.addEventListener('load', () => {
        import('swiper').then((module) => {
            const Swiper = module.default;
            const cs = getComputedStyle(document.documentElement);

            //MAIN BANNER
            const mainBanner = new Swiper('.main-banner', {
                modules: [Pagination, Autoplay],
                preloadImages: false,
                autoplay: {
                    delay: 5000
                },
                lazy: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                }
            });

            //MAIN PAGE PRODUCTS
            const breakpoints = {
                1: {},
                375: {},
                601: {},
                1100: {},
                1280: {}
            };

            const slider1 = new Swiper('.main-page-products-wrapper.slider1 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button1',
                    prevEl: '.swiper-button-prev.slider-button1'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGap').replace('px', '')
            });

            slider1.on('breakpoint', function () {
                slider1.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider1.params.spaceBetween = +cs.getPropertyValue('--prodGap').replace('px', '');
                slider1.update();
            });

            const slider2 = new Swiper('.main-page-products-wrapper.slider2 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button2',
                    prevEl: '.swiper-button-prev.slider-button2'
                },
                loop: false
            });

            const slider3 = new Swiper('.main-page-products-wrapper.slider3 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button3',
                    prevEl: '.swiper-button-prev.slider-button3'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGap').replace('px', '')
            });

            slider3.on('breakpoint', function () {
                slider3.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider3.params.spaceBetween = +cs.getPropertyValue('--prodGap').replace('px', '');
                slider3.update();
            });

            //PRODUCERS
            const producersBanner = new Swiper('.producers-banner .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.producer-button',
                    prevEl: '.swiper-button-prev.producer-button'
                },
                loop: false,
                spaceBetween: 36,
                breakpoints: {
                    1: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    },
                    601: {
                        slidesPerView: 3
                    },
                    1100: {
                        slidesPerView: 4
                    },
                    1280: {
                        slidesPerView: 5
                    },
                    1681: {
                        slidesPerView: 6
                    }
                }
            });

            // Opinions
            var opinionsBanner = new Swiper('#all-comments .swiper-container', {
                preloadImages: false,
                modules: [Navigation],
                autoplay: {
                    delay: 8000
                },
                navigation: {
                    nextEl: '.swiper-button-next.opinion-button',
                    prevEl: '.swiper-button-prev.opinion-button'
                },
                loop: true,
                spaceBetween: 21,
                breakpoints: {
                    320: {
                        slidesPerView: 1
                    },
                    481: {
                        slidesPerView: 1
                    },
                    769: {
                        slidesPerView: 2
                    },
                    1025: {
                        slidesPerView: 3
                    },
                    1281: {
                        slidesPerView: 4
                    }
                }
            });

        });
    }, false);
}